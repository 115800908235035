<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t(`dialog.${title}`) }}
        </v-card-title>

        <v-card-text class="text-subtitle-1 text-md-h5 font-weight-medium">
          <v-row justify="center" class="my-auto">
            {{ref[1]}}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            {{ $t(`dialog.btn`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CoreModal',
  data: () => ({
    title: '',
    ref: []
  }),
  computed: {
    dialog: {
      get () {
        return this.$store.state.dialog
      },
      set (val) {
        this.setDialog(val)
      },
    },
  },
  methods: {
    ...mapMutations(['setDialog']),
  },
  created() {
    this.$eventHub.$on('open-dialog',(args) =>
    {
      this.title = args.name
      this.ref = args.ref.split(':')
      this.dialog = true
    })
  }
}
</script>

<style scoped>

</style>
